import { Link } from "react-router-dom"

const Legal = () => {
    return (
        <div className="flex justify-center items-center">
            Not yet complete.
        </div>
    )
}

export default Legal
