const Privacy = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                <h1 className="text-6xl poppins font-bold mb-4">
                    Privacy Policy
                </h1>
                Not yet complete.
            </div>
        </div>
    )
}

export default Privacy
