import Property from "@components/property/Property"
import { UserVipPreferences } from "@features/users/account/api/models/UserVipPreferences"
import { useEffect, useState } from "react"
import { useAtom } from "jotai"
import { toast } from "react-hot-toast"
import { getToken } from "@features/users/account/Account.atom"
import { getVip } from "@features/users/account/api/Vip"
import { setVipAdCode } from "@features/users/account/api/UpdateAccount"
import Modal from "@components/Modal"
import TextArea from "@components/inputs/TextArea"
import Button from "@components/inputs/Button"

const VipSettings = () => {
    const [vip, setVip] = useState<UserVipPreferences>()
    const [session] = useAtom(getToken)
    const [newAdCode, setNewAdCode] = useState("")

    // if the update modal is visible
    const [visible, setVisible] = useState(false)

    const [deleteLoading, setDeleteLoading] = useState(false) // if the delete button is loading
    const [loading, setLoading] = useState(false) // if the regular button is loading

    const [customAdCode, setCustomAdCode] = useState<string>("")

    useEffect(() => {
        const loadVip = async () => {
            try {
                const loadedVip = await getVip(session)

                setVip(loadedVip)
                setCustomAdCode(loadedVip.customAdCode)
            } catch (e) {
                toast.error("There was an issue loading your VIP preferences!")
            }
        }

        loadVip().then(() =>
            console.log("Successfully loaded VIP preferences.")
        )
    }, [session])

    // reset the current ad code
    const removeAdCode = async () => {
        setDeleteLoading(true)

        const response = await setVipAdCode(session, "")

        if (response) {
            toast.success("Successfully updated ad code!")
            setVisible(false)
        } else {
            toast.error("There was an issue updating the ad code.")
        }

        setDeleteLoading(false)
    }

    // update the current ad code
    const updateAdCode = async () => {
        if (
            !newAdCode
                .trim()
                .match(
                    /\/\/[A-Za-z0-9]{14}\.cloudfront\.net\/\?[A-Za-z]{5}=[0-9]{6}/
                )
        ) {
            toast.error(
                "Invalid ad code! Please make sure it's copied exactly!"
            )
            return
        }

        setLoading(true)

        const response = await setVipAdCode(session, newAdCode.trim())

        if (response) {
            toast.success("Successfully updated ad code!")
            setVisible(false)
        } else {
            toast.error("There was an issue updating the ad code.")
        }

        setLoading(false)
    }

    return (
        <>
            {/* Update ad code modal */}
            <Modal
                title="Modify VIP Ad Code"
                visible={visible}
                setVisible={setVisible}
            >
                <h2 className="text-lg text-center font-semibold">
                    Guide for VIP Users
                </h2>
                <ol className="list-decimal p-6">
                    <li>
                        <a
                            className={
                                "text-blue-400 hover:text-blue-600 hover:underline"
                            }
                            target={"_blank"}
                            href={
                                "https://ad-maven.com/publishers/?rpid=314694"
                            }
                        >
                            Create an AdMaven publisher account.
                        </a>
                    </li>
                    <li>
                        Under "Sites and Placements" add our site with the
                        following options:
                        <ul className="list-disc ml-5">
                            <li>Site name: pastebit</li>
                            <li>Site domain: https://pastebit.org/</li>
                            <li>Select Vertical: YouTube_Self_Build</li>
                            <li>Ad (Tag) - Pop</li>
                        </ul>
                    </li>
                    <li>
                        Copy the code & paste only
                        //fdnxcur.cloudfront.net/?ialfd=374821 (example code)
                    </li>
                </ol>

                <p className="pb-4">
                    If you cannot add a new site, using an existing placement
                    will work as a temporary solution
                </p>

                <TextArea
                    onChange={(e) => setNewAdCode(e.currentTarget.value)}
                    defaultValue={customAdCode}
                    placeholder={"//fdnxcur.cloudfront.net/?ialfd=374821"}
                />

                <p className="text-sm my-4">
                    The code you provide will replace ALL the ads provided by
                    pastebit. This updates ALL pastes, even if they have
                    modified ad settings. You can always reverse this by
                    removing any code within the box above.
                </p>

                <div className="flex flex-row gap-4">
                    <Button onClick={updateAdCode} loading={loading}>
                        Update
                    </Button>

                    <Button
                        onClick={removeAdCode}
                        buttonStyle="danger"
                        loading={deleteLoading}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>

            <div className="flex flex-col items-center lg:w-1/2 justify-center">
                <h2 className="text-lg text-center poppins my-4">
                    VIP Settings
                </h2>

                <Property
                    title={
                        <>
                            <i className="fa-solid fa-code" /> Custom Ad Code
                        </>
                    }
                    status={
                        <span className="monospace">
                            {customAdCode.length === 0
                                ? "You have no custom ad code!"
                                : customAdCode}
                        </span>
                    }
                    buttonAction={() => setVisible(true)}
                />
            </div>
        </>
    )
}

export default VipSettings
