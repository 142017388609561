import Editor from "@components/Editor"
import Button from "@components/inputs/Button"
import TextArea from "@components/inputs/TextArea"
import Input from "@components/inputs/Input"
import React, { useEffect, useState } from "react"
import Visibility from "@features/pastes/components/editor/Visibility"
import { Visibility as Vis } from "@features/pastes/api/Visibility"
import { useAtom } from "jotai"
import useTags from "@components/inputs/Tags"
import PasteMonetization from "@features/pastes/components/editor/PasteMonetization"
import {
    account,
    getToken,
    isLoggedIn,
} from "@features/users/account/Account.atom"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import { updateUserMonetizationStatus } from "@features/users/account/api/UpdateAccount"

type PasteEditorProps = {
    initPasteResponse?: GetPasteResponse
    usePresetFromBackend?: boolean
    loading: boolean
    onSubmit: (
        ev: React.FormEvent<HTMLFormElement>,
        pasteContents: string,
        tags: string[],
        file?: File
    ) => void
}

/**
 * An editor for a paste.
 * This is used for both the creator on the homepage and the edit page.
 */
const PasteEditor = ({
    loading,
    initPasteResponse,
    onSubmit,
}: PasteEditorProps) => {
    const [ses] = useAtom(getToken)
    const [acc] = useAtom(account)
    const { paste: initPaste } = initPasteResponse ?? {}

    // determines if the user can use diff features (ex: ads)
    const [loggedIn] = useAtom(isLoggedIn)

    // the thumbnail of the paste.
    const [thumbnail, setThumbnail] = useState<File>()

    // contents of paste
    const [pasteContents, setPasteContents] = useState<string>(
        initPasteResponse?.content ?? ""
    )

    // paste tags
    const [tags, tagsElement] = useTags(initPaste?.tags ?? [])

    // description length, shows a char count in the top right of the text area
    const [descLen, setDescLen] = useState<number>(
        initPaste?.description?.length ?? 0
    )

    return (
        <>
            <Editor
                value={pasteContents}
                onChange={(newStr) => setPasteContents(newStr)}
            />

            <form
                className="px-2"
                onSubmit={(e) => onSubmit(e, pasteContents, tags, thumbnail)}
            >
                <div className="flex grid-cols-4 flex-col gap-x-4 gap-y-4 lg:grid lg:gap-y-0">{/* Title of Paste */}
                    <label className="input-label w-full">
                        Paste Title
                        <Input
                            name="title"
                            placeholder="Paste title here"
                            maxLength={64}
                            defaultValue={initPaste?.title ?? ""}
                        />
                    </label>

                    {/* Paste Description */}
                    <div className="row-span-2">
                        <div className="flex flex-row items-center justify-between">
                            Paste Description
                            <span className="monospace text-sm text-greyText dark:text-dark_greyText">
                                {descLen}/256
                            </span>
                        </div>

                        <TextArea
                            name="description"
                            placeholder="Paste description here"
                            maxLength={256}
                            className="scrollbarHidden"
                            defaultValue={initPaste?.description ?? ""}
                            onChange={(e) =>
                                setDescLen(e.currentTarget.value.length)
                            }
                            style={{
                                height: "78.5%",
                            }}
                        />
                    </div>

                    {/* Game Link */}
                    {/*<label className="input-label w-full md:ml-0">*/}
                    {/*    Game Link*/}
                    {/*    <br />*/}
                    {/*    <Input*/}
                    {/*        name="gameLink"*/}
                    {/*        placeholder="Game link here"*/}
                    {/*        defaultValue={initPaste?.gameLink ?? ""}*/}
                    {/*        maxLength={128}*/}
                    {/*    />*/}
                    {/*</label>*/}

                    {/* Ad Configurations */}
                    <PasteMonetization extendSize defaultChecked={initPasteResponse?.paste?.monetized ?? true} />

                    {/* Youtube Video URL */}
                    {/*<label className="input-label w-full md:ml-0">*/}
                    {/*    YouTube Video URL*/}
                    {/*    <br />*/}
                    {/*    <Input*/}
                    {/*        name="youtubeLink"*/}
                    {/*        placeholder="Video URL"*/}
                    {/*        maxLength={128}*/}
                    {/*        defaultValue={initPaste?.youtubeLink ?? ""}*/}
                    {/*    />*/}
                    {/*</label>*/}

                    {/* Visibility of Paste */}
                    <label className="input-label w-full">
                        Visibility
                        <Visibility
                            defaultValue={initPaste?.visibility ?? Vis.PUBLIC}
                        />
                    </label>

                    {/* Thumbnail only if logged in */}
                    {loggedIn && (
                        <label className="input-label mt-0.5 w-full">
                            Thumbnail
                            <br />
                            <input
                                className="secondaryBackground w-full rounded-lg p-2"
                                style={{ fontSize: "0.75rem" }}
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) =>
                                    setThumbnail((e.target.files ?? [])[0])
                                }
                            />
                        </label>
                    )}

                    {/* Paste Tags */}
                    <label className="input-label w-full md:ml-0">
                        Tags
                        <br />
                        {tagsElement}
                    </label>
                </div>

                <div className="flex flex-row justify-center items-center">
                    <div className="mt-7 w-1/3">
                        <Button
                            type="submit"
                            className="w-full"
                            loading={loading}
                        >
                            Publish
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default PasteEditor
