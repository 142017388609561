import React from "react"
import Button from "@components/inputs/Button"
import { Link } from "react-router-dom"
import { useAtom } from "jotai"
import { registerModalVisibility } from "@features/users/login/Login.atom"
import { isLoggedIn } from "@features/users/account/Account.atom"

/**
 * The landing page. `/landing`
 */
export default function Landing() {
    const [, setRegisterVisible] = useAtom(registerModalVisibility)
    const [loggedIn] = useAtom(isLoggedIn)

    return (
        <div className="flex flex-col">
            <div
                style={{ backgroundImage: "url('/img/background.jpg')" }}
                className="my-16 flex w-full flex-row items-center justify-center gap-8 rounded-lg p-8"
            >
                <div className="flex flex-col items-center justify-center">
                    <h1 className="titleFont text-6xl font-semibold text-primary dark:text-dark_primary">
                        pastebit
                    </h1>
                    <h3 className="poppins max-w-lg text-3xl">
                        Get Paid to Paste.
                    </h3>

                    <Button
                        buttonStyle="primary"
                        className="mt-4"
                        disabled={loggedIn === true}
                        onClick={() => setRegisterVisible(true)}
                    >
                        Get started
                    </Button>
                </div>
            </div>

            <div className="my-16 flex flex-col items-center justify-center gap-4">
                <h3 className="poppins max-w-2xl text-4xl">
                    Start with the best.
                </h3>

                <div className="flex flex-col justify-evenly gap-4 md:flex-row">
                    <div className="island poppins w-[300px] rounded-lg p-4">
                        <p className="text-center text-xl">pastebit</p>

                        <p>
                            <i className="fa-solid fa-check"></i> All of our
                            features available, for free.
                        </p>
                        <p>
                            <i className="fa-solid fa-check"></i> Crypto,
                            PayPal, and fee free Robux payouts.
                        </p>
                        <p>
                            <i className="fa-solid fa-check"></i> Quick &
                            helpful support.
                        </p>
                    </div>

                    <div className="poppins w-[300px] rounded-lg bg-dark_secondaryBackground p-4">
                        <p className="text-center text-xl">Competitors</p>

                        <p>
                            <i className="fa-solid fa-xmark"></i> Features
                            locked behind paywalls.
                        </p>
                        <p>
                            <i className="fa-solid fa-xmark"></i> Limited payout
                            methods with excessive fees.
                        </p>
                        <p>
                            <i className="fa-solid fa-xmark"></i> Lacking
                            support.
                        </p>
                    </div>
                </div>
            </div>

            <div className="island my-16 flex flex-row justify-start">
                <div className="flex flex-col gap-8 rounded-lg p-8">
                    <h3 className="poppins max-w-2xl text-4xl">
                        The easiest way to earn revenue from your pastes.
                    </h3>

                    <p className="poppins max-w-lg">
                        We provide world-class tooling to earn revenue
                        efficiently from your pastes. Completely customize ads
                        on a per-paste basis, and watch the revenue pour in.
                    </p>
                </div>
            </div>

            <div className="my-16 flex flex-row justify-end">
                <div className="flex flex-col gap-8 rounded-lg p-8">
                    <h3 className="poppins max-w-2xl text-4xl">
                        Withdraw anytime, anywhere.
                    </h3>

                    <p className="poppins max-w-lg">
                        Earnings from your pastes are provided every day.
                        Withdraw them anytime, anywhere, through PayPal, Monero,
                        or Robux.
                    </p>
                </div>
            </div>

            <div className="island my-16 flex flex-row justify-start">
                <div className="flex flex-col gap-8 rounded-lg p-8">
                    <h3 className="poppins max-w-2xl text-4xl">
                        Advanced features from the start.
                    </h3>

                    <p className="poppins max-w-lg">
                        We provide advanced features to any user, for free.
                        Anyone can change any aspect about their paste or
                        profile right after they create their account. We also
                        offer our extensive Key API to all users,{" "}
                        <Link
                            className="text-blue-600 underline hover:text-blue-500"
                            to={"/keyapi"}
                        >
                            learn more here
                        </Link>
                        .
                    </p>
                </div>
            </div>

            <div className="my-16 flex flex-row justify-center">
                <div className="flex flex-col gap-8 rounded-lg p-8">
                    <h3 className="poppins max-w-2xl text-4xl">
                        Ready to get started?
                    </h3>

                    <Button
                        onClick={() => setRegisterVisible(true)}
                        disabled={loggedIn === true}
                    >
                        Register
                    </Button>
                </div>
            </div>
        </div>
    )
}
