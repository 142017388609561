import { useAtom } from "jotai"
import React, { FormEvent, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { LoginResponse } from "@features/users/login/api/responses/LoginResponse"
import { MfaLoginResponse } from "@features/users/login/api/responses/MfaLoginResponse"
import Modal from "@components/Modal"
import Button from "@components/inputs/Button"
import Input from "@components/inputs/Input"
import {
    forgotPasswordVisibility,
    loginModalVisibility,
    twoFactorContinue,
    twoFactorVisibility,
} from "@features/users/login/Login.atom"
import { AccountStore } from "@features/users/account/api/models/AccountStore"
import { account, setToken } from "@features/users/account/Account.atom"
import { administratorLogin, login } from "@features/users/login/api/Login"
import { useCookies } from "react-cookie"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import {INVIS_CLOUDFLARE_SITE_KEY, TOR} from "@util/Util"

/**
 * Overscreen modal with login for username and password.
 */
const LoginModal = () => {
    const [, setCookie] = useCookies(["token"])
    const ref = React.useRef<TurnstileInstance>()

    const nav = useNavigate()

    const [loading, setLoading] = useState(false)

    // Visiblity of the login modal
    const [visible, setVisible] = useAtom(loginModalVisibility)

    const [, setTwoFactorVisible] = useAtom(twoFactorVisibility)
    const [, setTwoFactorContinue] = useAtom(twoFactorContinue)
    const [, setForgotPasswordVisible] = useAtom(forgotPasswordVisibility)

    // Used in the onSubmit to configure login data\
    const [, setSesToken] = useAtom(setToken)
    const [, setAccStore] = useAtom(account)

    // When the login form is submitted.
    const onSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        setLoading(true)

        const formData = new FormData(ev.currentTarget)

        let username = formData.get("username") as string,
            password = formData.get("password") as string,
            remember = formData.get("remember") === "true"

        const turnstileToken = ref.current?.getResponse()

        if (
            username &&
            password &&
            (turnstileToken || process.env.NODE_ENV === "development" || TOR)
        ) {
            ref.current?.reset()

            try {
                if (username.startsWith("@")) {
                    const login = await administratorLogin(
                        username.replace("@", ""),
                        password,
                        turnstileToken
                    )

                    setCookie("token", login)
                    window.location.href = "/admin"
                    return
                }

                const loginResponse = await login(
                    username,
                    password,
                    remember,
                    turnstileToken
                )

                // user has 2fa
                if (Object.hasOwn(loginResponse, "mfaVerification")) {
                    setVisible(false)
                    setTwoFactorVisible(true)
                    setTwoFactorContinue(loginResponse as MfaLoginResponse)
                } else {
                    const { token, user } = loginResponse as LoginResponse

                    if (!token || !user || token === "") {
                        toast.error(
                            "There was an issue with your login request, please try again."
                        )
                        return
                    }

                    toast.success(`Welcome back, ${user.user.username}`)

                    if (token !== "") setSesToken(token)

                    setAccStore({
                        payment: user.payment,
                        username: user.user.username,
                        userId: user.user.userID,
                        email: user.email,
                        accountCreation: user.user.registrationDate,
                        mfaEnabled: user.mfaEnabled,
                        accountMonetized: user.accountMonetized,
                    } as AccountStore)

                    setVisible(false)
                }
            } catch (e) {
                toast.error(`${e}`)
            }
        }

        setLoading(false)
    }

    // when the create account button is clicked
    const registerButton = () => {
        setVisible(false)
        nav("/register")
    }

    // when the forgot password button is clicked
    const forgotButton = () => {
        setVisible(false)
        setForgotPasswordVisible(true)
    }

    return (
        <>
            <Turnstile
                id="login"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            <Modal visible={visible} setVisible={setVisible} title="Login">
                <form className="space-y-6" onSubmit={onSubmit}>
                    {/* Username input */}
                    <div>
                        <label
                            htmlFor="user"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Username
                        </label>

                        <Input
                            type="text"
                            name="username"
                            id="user"
                            placeholder="Your username"
                            required
                        />
                    </div>

                    {/* Password input */}
                    <div>
                        <label
                            htmlFor="password"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Password
                        </label>

                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Your password"
                            required
                        />
                    </div>

                    {/* Lost Password & Remember me */}
                    <div className="flex justify-between">
                        <div className="flex items-start">
                            <div className="flex items-center h-5">
                                <Input
                                    name="remember"
                                    id="remember"
                                    type="checkbox"
                                    value="true"
                                />

                                <label
                                    htmlFor="remember"
                                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Remember me
                                </label>
                            </div>
                        </div>

                        <p
                            onClick={forgotButton}
                            className="cursor-pointer text-sm text-blue-700 hover:underline dark:text-blue-500"
                        >
                            Lost Password?
                        </p>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-2">
                        <Button type="submit" loading={loading}>
                            Login to your account
                        </Button>
                    </div>

                    {/* Not Registered button */}
                    <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                        Not registered?{" "}
                        <button
                            type="button"
                            onClick={registerButton}
                            className="text-blue-700 hover:underline dark:text-blue-500"
                        >
                            Create account
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default LoginModal
