import React from "react"
import ReactDOM from "react-dom/client"
import {App} from "./App"
import "./styles/index.scss"
import "./styles/main.scss"
import reportWebVitals from "./reportWebVitals"
import {Provider} from "jotai"
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

console.info(
    `pastebit ${process.env.REACT_APP_VERSION}, ${process.env.REACT_APP_LINE}`
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

if (
    localStorage.getItem("colorTheme") === "dark" ||
    (!("colorTheme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
)
    document.documentElement.classList.add("dark")
else document.documentElement.classList.remove("dark")

root.render(
    <React.StrictMode>
        <Provider>
            <DevSupport ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
            >
                <App/>
            </DevSupport>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
