import React, { useEffect, useState } from "react"
import Button from "@components/inputs/Button"
import { useNavigate, useParams } from "react-router-dom"
import { finalizeProcess, startProcess } from "@features/keys/api/PasteKeys"
import toast from "react-hot-toast"
import { Helmet } from "react-helmet"
import AdHandler from "@features/pastes/components/viewer/AdHandler"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { INVIS_CLOUDFLARE_SITE_KEY } from "@util/Util"

/**
 * The process of retrieving a key for a paste.
 */
const Interstitial = () => {
    const ref = React.useRef<TurnstileInstance>()

    let { id } = useParams() // id of the paste
    const nav = useNavigate()

    const [token, setToken] = useState<string>()
    const [customAdCode, setCustomAdCode] = useState<string>()
    const [monetized, setMonetized] = useState<boolean>()

    // manages the time remaining til the user can advance
    const [secondsRemaining, setSecondsRemaining] = useState(5)
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsRemaining((current) => current - 1)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        // begin the process & get initial token
        startProcess(id as string)
            .then((response) => {
                setToken(response.token)
                setMonetized(response.monetized)
                setCustomAdCode(response.customAdCode)
            })
            .catch(() => {
                // send the user back to the homepage if their paste ID is invalid
                toast.error("That paste is invalid!")
                nav("/")

                return undefined
            })
    }, [id, nav])

    // retrieve the final token (aka key) and
    // send the user to view it
    const retrieveFinalToken = () => {
        finalizeProcess(token ?? "", ref.current?.getResponse() ?? "")
            .catch(() =>
                toast.error(
                    "There was an issue getting your key, please try again."
                )
            )
            .then((key) => nav("/key/" + key))
            .finally(() => ref.current?.reset())
    }

    const usingCustom =
        customAdCode !== null &&
        customAdCode !== undefined &&
        customAdCode !== ""

    return (
        <div className="flex flex-col justify-center w-full h-full items-center mt-6 gap-8">
            {!usingCustom && monetized && (
                <Helmet>
                    <AdHandler />
                </Helmet>
            )}

            <Turnstile
                id="interstitial"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            <Helmet>
                {/*  300x250 (1) */}
                <script type="text/javascript">{`
                    if (typeof atAsyncOptions !== 'object') var atAsyncOptions = [];
                    atAsyncOptions.push({
                    'key': '4dec5edefbfd1e33a7a29084b72c1f78',
                    'format': 'js',
                    'async': true,
                    'container': 'atContainer-4dec5edefbfd1e33a7a29084b72c1f78',
                    'params' : {}
                });
                    var script = document.createElement('script');
                    script.type = "text/javascript";
                    script.async = true;
                    script.src = 'http' + (location.protocol === 'https:' ? 's' : '') + '://www.effectivecreativeformat.com/4dec5edefbfd1e33a7a29084b72c1f78/invoke.js';
                    document.getElementsByTagName('head')[0].appendChild(script);
                    `}</script>

                {/* Native Banner */}
                <script
                    async={true}
                    data-cfasync="false"
                    src="//pl18041507.highperformancecpmgate.com/4cece80644fd024919291caae44ce429/invoke.js"
                ></script>

                {/* 468 x 60 */}
                <script type="text/javascript">{`
                    if (typeof atAsyncOptions !== 'object') var atAsyncOptions = [];
                    atAsyncOptions.push({
                    'key': 'd33411b3a648bdf090e6baf0dd64c2f4',
                    'format': 'js',
                    'async': true,
                    'container': 'atContainer-d33411b3a648bdf090e6baf0dd64c2f4',
                    'params' : {}
                });
                    var script = document.createElement('script');
                    script.type = "text/javascript";
                    script.async = true;
                    script.src = 'http' + (location.protocol === 'https:' ? 's' : '') + '://www.effectivecreativeformat.com/d33411b3a648bdf090e6baf0dd64c2f4/invoke.js';
                    document.getElementsByTagName('head')[0].appendChild(script);
                `}</script>
            </Helmet>

            {/* Native Banner */}
            <div
                className="max-w-6xl"
                id="container-4cece80644fd024919291caae44ce429"
            ></div>

            <div className="flex flex-col items-center justify-center gap-8 w-full">
                {/* 350x250 (1) */}
                <div id="atContainer-4dec5edefbfd1e33a7a29084b72c1f78"></div>

                <Button
                    buttonStyle="success"
                    disabled={secondsRemaining > 0}
                    onClick={retrieveFinalToken}
                >
                    Continue to Paste{" "}
                    {secondsRemaining > 0
                        ? `in ${secondsRemaining} seconds`
                        : ""}
                </Button>
            </div>

            {/* 468x60 */}
            <div id="atContainer-d33411b3a648bdf090e6baf0dd64c2f4"></div>
        </div>
    )
}

export default Interstitial
