import Button from "@components/inputs/Button"
import Paginator from "@components/Paginator"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    disablePaste,
    getPasteKeyAnalytics,
} from "@features/keys/api/ManagePasteKeys"
import { useAtom } from "jotai"
import { getToken } from "@features/users/account/Account.atom"
import toast from "react-hot-toast"
import {
    keyCreationProcess,
    KeyEnabledPastes,
    keySettings,
} from "@features/keys/Keys.atom"
import Spinner from "@components/Spinner"
import usePasteKeyAnalytics from "@features/keys/hooks/usePasteKeyAnalytics"
import GetKeyEnabledPaste from "@features/keys/api/models/responses/GetKeyEnabledPaste"
import KeySettings from "@features/keys/components/KeySettings"
import KeyDetailSelection from "@features/keys/components/creation/KeyDetailSelection"
import KeyPasteSelection from "@features/keys/components/creation/KeyPasteSelection"
import KeyOverview from "@features/keys/components/creation/KeyOverview"

/**
 * A specific paste that has the key system enabled.
 */
const PasteEntry: React.FC<{ paste: GetKeyEnabledPaste; index: number }> = ({
    paste,
    index,
}) => {
    const [ses] = useAtom(getToken)
    const [data, setData] = useAtom(KeyEnabledPastes)

    const [, setSettings] = useAtom(keySettings)

    // if the delete button is loading
    const [deleteLoading, setDeleteLoading] = useState(false)

    const disablePasteKey = async () => {
        setDeleteLoading(true)

        disablePaste(ses, paste.paste.pasteID)
            .then(() => {
                toast.success("Successfully disabled keys for that paste!")

                let keys = Object.keys(data)

                for (let i = 0; keys.length > i; i++) {
                    const keyIndex = i + 1 // pages start at 1

                    let pasteKeys = data[keyIndex] as GetKeyEnabledPaste[]

                    let includes = pasteKeys.filter(
                        (pasteKey) =>
                            pasteKey.paste.pasteID !== paste.paste.pasteID
                    )

                    if (pasteKeys.length !== includes.length) {
                        setData((prev: any) => ({
                            ...prev,
                            [keyIndex]: [...includes],
                        }))
                        break
                    }
                }
            })
            .catch((message) => toast.error(message))
            .finally(() => setDeleteLoading(false))
    }

    const copyLink = () => {
        navigator.clipboard
            .writeText(
                `https://pastebit.org/interstitial/${paste?.paste?.pasteID}`
            )
            .then(() => toast.success("Copied link to clipboard!"))
    }

    return (
        <tr
            className={`${
                index % 2 === 0 ? "secondaryBackground" : "primaryBackground"
            } border-b border-stone-300 dark:border-stone-800`}
        >
            <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
                {paste.key.keyName === "" ? "Untitled Key" : paste.key.keyName}
            </th>

            <th
                scope="row"
                className="cursor-pointer py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
                <Link
                    to={`/${paste.paste.pasteID}`}
                    className="hover:underline"
                >
                    {paste.paste.title === ""
                        ? "Untitled Paste"
                        : paste.paste.title}
                </Link>
            </th>

            <td className="py-4 px-6 flex flex-row gap-4">
                <button onClick={() => disablePasteKey()}>
                    {!deleteLoading ? (
                        <i className="fa-solid fa-trash"></i>
                    ) : (
                        <Spinner />
                    )}
                </button>

                <button onClick={copyLink}>
                    <i className="fa-solid fa-copy"></i>
                </button>

                <button onClick={() => setSettings(paste)}>
                    <i className="fa-solid fa-cog"></i>
                </button>
            </td>
        </tr>
    )
}

/**
 * Pastes that have the key system enabled
 * @constructor
 */
const EnabledKeyPastes = () => {
    const [analytics] = usePasteKeyAnalytics(true)
    const [session] = useAtom(getToken)

    // information about the page
    const [maxPage, setMaxPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)

    const [data, setData] = useAtom(KeyEnabledPastes)

    // this is from the initial loading of paste keys in analytics
    useEffect(() => {
        const response = analytics?.pasteKeys

        if (response) {
            setMaxPage(response.maxPage)
            setCurrentPage(response.currentPage)
            setTotalResults(response.totalResults)
            setData((prev: any) => ({
                ...prev,
                [response.currentPage]: [...response.pageContents],
            }))
        }
    }, [analytics, setData])

    // when the paginator changes the page, load the new page
    useEffect(() => {
        // loads a page by it's page number
        const loadPage = (page: number) => {
            getPasteKeyAnalytics(session, page)
                .then(({ pasteKeys: response }) => {
                    setMaxPage(response.maxPage)
                    setCurrentPage(response.currentPage)
                    setTotalResults(response.totalResults)
                    setData((prev: any) => ({
                        ...prev,
                        [response.currentPage]: [...response.pageContents],
                    }))
                })
                .catch((error) => toast.error(error))
                .finally(() =>
                    console.log(`Finished loading ${page} page of Paste Keys!`)
                )
        }

        if (!data[currentPage] && analytics && currentPage !== 1) {
            loadPage(currentPage)
        }
    }, [analytics, currentPage, data, session, setData])

    const [, setStep] = useAtom(keyCreationProcess)

    return (
        <div className="min-w-[50%] max-w-[50%] flex flex-col md:w-auto items-center justify-center primaryBackground p-4 rounded-lg md:mb-0 mb-4">
            <h3 className="poppins text-lg mb-4">Enabled Pastes</h3>

            <KeySettings />

            <KeyDetailSelection />
            <KeyPasteSelection />
            <KeyOverview />

            {Object.hasOwn(data, currentPage) &&
            data[currentPage].length > 0 ? (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" className="w-36 py-3 px-6">
                                Name
                            </th>

                            <th scope="col" className="w-36 py-3 px-6">
                                Paste
                            </th>

                            <th scope="col" className="py-3 px-6">
                                Manage
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data[currentPage].map(
                            (pasteKey: GetKeyEnabledPaste, index: number) => (
                                <PasteEntry paste={pasteKey} index={index} />
                            )
                        )}
                    </tbody>
                </table>
            ) : (
                <div className="flex items-center ml-6 justify-center">
                    <p>You have no enabled pastes!</p>
                </div>
            )}

            {/* Key system controls */}
            <div className="flex md:flex-row flex-col items-center md:gap-8 gap-2 justify-between mt-6 mb-4">
                {/* Begin key creation process */}
                <Button onClick={() => setStep(1)}>
                    Enable <i className="fa-solid fa-plus"></i>
                </Button>

                <Paginator
                    disableResultsSummary={true}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    totalResults={totalResults}
                    pageCount={maxPage}
                />
            </div>
        </div>
    )
}

export default EnabledKeyPastes
