import {atom, useAtom} from "jotai"
import React, {useEffect, useState} from "react"
import {toast} from "react-hot-toast"
import {PasteViewCount} from "@features/pastes/api/models/Paste"
import Paginator from "@components/Paginator"
import Spinner from "@components/Spinner"
import {Visibility} from "@features/pastes/api/Visibility"
import {capitalize, timeAgo} from "@util/Util"
import {Link, useNavigate} from "react-router-dom"
import {useDeletePasteToast} from "@features/pastes/components/Paste"
import {getToken} from "@features/users/account/Account.atom"
import {getUserPastes} from "@features/users/account/api/ViewAccount"
import Input from "@components/inputs/Input"

/**
 * Props for a paste entry.
 */
type PasteEntryProps = {
    pasteViewCount: PasteViewCount
    index: number
    onPasteClick?: (pasteID: string, title: string) => void
    hideCategories?: string[]
}

/**
 * A paste entry in the user's pastes table
 */
const PasteEntry: React.FC<PasteEntryProps> = ({
    pasteViewCount,
    index,
    onPasteClick,
    hideCategories,
}) => {
    const { paste, viewCount } = pasteViewCount
    const nav = useNavigate()
    const [session] = useAtom(getToken)
    const deletePaste = useDeletePasteToast(session, paste.pasteID)

    const copyPasteLink = () => {
        navigator.clipboard
            .writeText(`https://${window.location.hostname}/${paste.pasteID}`)
            .then(() => toast.success("Copied link to paste to clipboard!"))
    }

    return (
        <tr
            className={`${
                index % 2 === 0 ? "secondaryBackground" : "primaryBackground"
            } border-b border-stone-300 dark:border-stone-800`}
        >
            {!hideCategories?.includes("title") && (
                <th
                    scope="row"
                    className="cursor-pointer whitespace-nowrap py-4 px-6 font-medium text-gray-900 dark:text-white"
                >
                    {onPasteClick ? (
                        <button
                            onClick={() =>
                                onPasteClick(paste.pasteID, paste.title)
                            }
                        >
                            {paste.title === ""
                                ? "Untitled Paste"
                                : paste.title}
                        </button>
                    ) : (
                        <Link to={`/${paste.pasteID}`}>
                            {paste.title === ""
                                ? "Untitled Paste"
                                : paste.title}
                        </Link>
                    )}
                </th>
            )}

            {/* Paste views */}
            {!hideCategories?.includes("views") && (
                <td className="py-4 px-6">{viewCount}</td>
            )}

            {!hideCategories?.includes("created") && (
                <td className="py-4 px-6">{timeAgo(new Date(paste.date))}</td>
            )}

            {!hideCategories?.includes("visibility") && (
                <td className="py-4 px-6">
                    {capitalize(Visibility[paste.visibility])}
                </td>
            )}

            {!hideCategories?.includes("monetized") && (
                <td className="py-4 px-6">
                    {paste.monetized ? "Monetized" : "Un-monetized"}
                </td>
            )}

            {!hideCategories?.includes("manage") && (
                <td className="py-4 px-6">
                    <button onClick={copyPasteLink}>
                        <i className="fa-solid fa-copy"></i>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <Link to={`/edit/${paste.pasteID}`}>
                        <i className="fa-solid fa-pen-to-square"></i>
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={deletePaste}>
                        <i className="fa-solid fa-trash"></i>
                    </button>
                </td>
            )}
        </tr>
    )
}

export const PastesAtom = atom<any>({})

type UserPastesProps = {
    onPasteClick?: (pasteID: string, title: string) => void
    hideTitle?: boolean
    titleWidth?: string
    hideCategories?: string[]
}

/**
 * A user's pastes, shown on the dashboard.
 */
export default function UserPastes({
    onPasteClick,
    hideTitle,
    titleWidth,
    hideCategories,
}: UserPastesProps) {
    const [searchQuery, setSearchQuery] = useState<string>()

    // the user's loaded pastes.
    // formed in page numbers to contents.
    // ex: { 1: Paste[], 2: Paste[] }
    const [pastes, setPastes] = useAtom(PastesAtom)

    // page information
    const [page, setPage] = useState(1)
    const [itemCount, setItemCount] = useState(0)
    const [maxPage, setMaxPage] = useState(0)

    const [sesToken] = useAtom(getToken)

    // load a page when page changes
    useEffect(() => {
        // load the page by the page number
        const loadPage = async (page: number) => {
            // make sure the page hasn't been loaded before
            if (!Object.hasOwn(pastes, page)) {
                try {
                    const pastesRequest = await getUserPastes(
                        sesToken,
                        page,
                        searchQuery
                    )

                    setMaxPage(pastesRequest.pageCount)
                    setItemCount(pastesRequest.itemCount)

                    setPastes((prev: any) => ({
                        ...prev,
                        [page]: pastesRequest.pastes,
                    }))
                } catch (e) {
                    toast.error(`${e}`)
                }
            }
        }

        loadPage(page).then(() => console.log("User Pastes page loaded!"))
    }, [sesToken, page, pastes, searchQuery, setPastes])

    const enterSearch = () => {
        console.log("Searching")

        setPastes({})
        setPage(1)
    }

    return (
        <div className="scrollbarHidden primaryBackground relative overflow-x-auto rounded-lg p-4">
            {(hideTitle === true || hideTitle === undefined) && (
                <h1 className="poppins mb-4 text-center text-4xl font-semibold">
                    Your Pastes
                </h1>
            )}

            <div className="mb-4 flex flex-col items-center justify-center gap-4 md:flex-row">
                <div className="md:w-1/3">
                    <Input
                        placeholder={"Search your pastes"}
                        onEnter={enterSearch}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.currentTarget.value)}
                        iconRight={
                            <div className="flex flex-row gap-2">
                                <button onClick={enterSearch}>
                                    <i className="fa-solid fa-search" />
                                </button>
                                <button
                                    onClick={() => {
                                        setSearchQuery("")
                                        enterSearch()
                                    }}
                                >
                                    <i className="fa-solid fa-undo" />
                                </button>
                            </div>
                        }
                    />
                </div>

                <Paginator
                    currentPage={page}
                    setCurrentPage={setPage}
                    maxPage={maxPage}
                    totalResults={itemCount}
                    pageCount={50}
                    disableResultsSummary={true}
                />
            </div>

            {/* Pastes table itself */}
            {Object.hasOwn(pastes, page) ? (
                <div>
                    <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
                        <thead className="text-xs uppercase text-gray-700 dark:text-gray-400">
                            <tr>
                                {/* Paste title */}
                                {!hideCategories?.includes("title") && (
                                    <th
                                        scope="col"
                                        className={`${
                                            titleWidth ? titleWidth : "w-48"
                                        }py-3 px-6`}
                                    >
                                        Title
                                    </th>
                                )}

                                {/* Paste views */}
                                {!hideCategories?.includes("views") && (
                                    <th scope="col" className="py-3 px-6">
                                        Views
                                    </th>
                                )}

                                {/* Date created*/}
                                {!hideCategories?.includes("created") && (
                                    <th scope="col" className="w-40 py-3 px-6">
                                        Created
                                    </th>
                                )}

                                {/* Visibility */}
                                {!hideCategories?.includes("visibility") && (
                                    <th scope="col" className="py-3 px-6">
                                        Visibility
                                    </th>
                                )}

                                {/* Ads category */}
                                {!hideCategories?.includes("monetized") && (
                                    <th scope="col" className="w-48 py-3 px-6">
                                        Monetized
                                    </th>
                                )}

                                {/* Manage paste */}
                                {!hideCategories?.includes("manage") && (
                                    <th scope="col" className="py-3 px-6">
                                        Manage
                                    </th>
                                )}
                            </tr>
                        </thead>

                        <tbody>
                            {pastes[page].map(
                                (paste: PasteViewCount, i: number) => (
                                    <PasteEntry
                                        pasteViewCount={paste}
                                        index={i}
                                        hideCategories={hideCategories}
                                        onPasteClick={onPasteClick}
                                    />
                                )
                            )}
                        </tbody>
                    </table>

                    <Paginator
                        currentPage={page}
                        setCurrentPage={setPage}
                        maxPage={maxPage}
                        totalResults={itemCount}
                        pageCount={50}
                    />
                </div>
            ) : (
                // when the pastes are loading
                <div className="flex flex-row justify-center">
                    <Spinner />
                </div>
            )}
        </div>
    )
}
