import React from "react"
import { useAtom } from "jotai"
import { isLoggedIn } from "@features/users/account/Account.atom"
import Button from "@components/inputs/Button"
import { statusReport } from "@features/status/Status.atom"

type PasteMonetizationProps = {
    defaultChecked: boolean
    extendSize?: boolean
    onSave?: () => void
    hideTitle?: boolean
}

/**
 * The ad config seen on the home creator
 */
export default function PasteMonetization({
    defaultChecked,
    extendSize,
    onSave,
    hideTitle,
}: PasteMonetizationProps) {
    const [loggedIn] = useAtom(isLoggedIn)
    const [status] = useAtom(statusReport)

    if (!status) return <></>

    return (
        <div
            className={`${
                loggedIn ? "row-span-2" : "row-span-3"
            } flex flex-col`}
        >
            {!hideTitle && (
                <p className="input-label ml-2 -mb-2 w-full pr-2 md:ml-0">
                    Monetization
                </p>
            )}

            <div
                className={`flex flex-row gap-2 ${
                    extendSize && "justify-between"
                } ${!loggedIn && "cursor-not-allowed"}`}
            >
                <div
                    className={`${
                        extendSize && "w-full justify-between"
                    } secondaryBackground flex flex-row items-center rounded-lg border p-2 px-3 dark:border-dark_border`}
                >
                    <span className="mr-3 text-sm text-gray-900 dark:text-gray-300">
                        Monetized
                    </span>

                    <label
                        className={`relative inline-flex items-center ${
                            loggedIn ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                        }`}
                    >
                        <input
                            type="checkbox"
                            defaultChecked={defaultChecked}
                            name="paste-monetized"
                            disabled={!loggedIn}
                            className="peer sr-only"
                        />

                        <div
                            className="peer h-6 w-11
                    rounded-full bg-gray-200 after:absolute after:top-0.5
                    after:left-[2px] after:h-5
                     after:w-5 after:rounded-full
                     after:border after:border-gray-300 after:bg-white
                     after:transition-all after:content-[''] peer-checked:bg-success
                     peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-green-300
                     dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-green-800"
                        ></div>
                    </label>
                </div>

                {onSave && <Button>Save</Button>}
            </div>

            {!loggedIn && (
                <p className="text-highlight dark:text-dark_highlight cursor-pointer pt-4 text-center text-blue-500 hover:text-blue-600">
                    Sign In to Earn
                </p>
            )}
        </div>
    )
}
