import {useAtom} from "jotai"
import React, {FormEvent, useState} from "react"
import toast from "react-hot-toast"
import {useNavigate} from "react-router-dom"
import {createPaste} from "@features/pastes/api/Pastes"
import PasteEditor from "@features/pastes/components/editor/PasteEditor"
import {getToken} from "@features/users/account/Account.atom"
import {Turnstile, TurnstileInstance} from "@marsidev/react-turnstile"
import {INVIS_CLOUDFLARE_SITE_KEY} from "@util/Util"
import {statusReport} from "@features/status/Status.atom"
import {PastesAtom} from "@features/dashboard/components/UserPastes";

/**
 * The ability to create pastes on the homepage.
 * Includes description, title, and the editor.
 */
const PasteCreator = () => {
    const ref = React.useRef<TurnstileInstance>()
    const [, setDashboardPastes] = useAtom(PastesAtom)

    // if the button should have a spinner in it
    const [loading, setLoading] = useState(false)

    const [sesToken] = useAtom(getToken)
    const nav = useNavigate()

    // when the create button is pressed
    const submitPaste = async (
        ev: FormEvent<HTMLFormElement>,
        pasteContents: string,
        tags: string[],
        file?: File
    ) => {
        ev.preventDefault()
        setLoading(true)

        const data = new FormData(ev.currentTarget)

        let title = data.get("title") as string,
            desc = data.get("description") as string,
            gameLink = data.get("gameLink") as string,
            youtubeLink = data.get("youtubeLink") as string,
            visibility = data.get("visibility") as string,
            monetized = data.get("paste-monetized") as string

        // there must be at least 1 character in the editor
        if (pasteContents.length === 0 || title === null || desc === null) {
            setLoading(false)
            toast.error("Your paste must have at least 1 character!")
            return
        }

        try {
            const req = await createPaste(
                sesToken,
                title,
                desc,
                gameLink,
                youtubeLink,
                tags,
                pasteContents,
                visibility,
                monetized,
                ref.current?.getResponse(),
                file
            )

            nav(`/${req.pasteID}`)
            toast.success("Your paste has been created!")

            // reset so this reloads
            setDashboardPastes({ })
        } catch (e) {
            toast.error(`${e}`)
        }

        ref.current?.reset()
        setLoading(false)
    }

    return (
        <section className="island flex w-full flex-col gap-4 rounded-lg p-2 lg:w-3/4">
            <Turnstile
                id="paste-creator"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            <PasteEditor
                loading={loading}
                usePresetFromBackend={true}
                onSubmit={submitPaste}
            />
        </section>
    )
}
export default PasteCreator
