/**
 * Calculate the base URL depending on the node_env
 */
const findBaseUrl = (): string => {
    switch (process.env.REACT_APP_LINE) {
        case "development":
            return "http://localhost:80/api/v1"
        case "production":
            return "https://pastebit.org/api/v1"
        case "staging":
            return "https://dev.pastebit.org/api/v1"
        default:
            return "https://pastebit.org/api/v1"
    }
}

/**
 * The base URL of the API.
 * Depends on what `NODE_ENV` is set to.
 */
export const BASE_URL = findBaseUrl()

/**
 * If they're using the TOR site
 */
export const TOR = window.location.hostname === "bloxybinefarao6ig5g4lrbut2ma2th5tcs7u2kjgkby7isussurjqyd.onion"

/**
 * The cloudflare site key.
 */
export const CLOUDFLARE_SITE_KEY = "0x4AAAAAAABgqw7Bkp8mmEm0"

/**
 * The invisible cloudflare site key.
 */
export const INVIS_CLOUDFLARE_SITE_KEY =
    process.env.REACT_APP_LINE === "development"
        ? "0x4AAAAAAAB2cZNkr60dTaS8"
        : "0x4AAAAAAAR6Pd64aFX9ORoY"

/**
 * The minimum payout amount in USD. This should replicate the backend.
 */
export const MIN_USD_PAYOUT = 0.01

/**
 * How many pastes come on one page.
 */
export const PASTE_PAGE_COUNT = 50

/**
 * Capitalize and clean a string. (Mostly for enums)
 *
 * @param str The string to capitalize.
 */
export const capitalize = (str: string): string => {
    let cleanedStr = str.toLowerCase()

    if (cleanedStr.includes("_")) cleanedStr = cleanedStr.split("_")[0]

    return str[0].toUpperCase() + cleanedStr.substring(1, cleanedStr.length)
}

/**
 * Calculate the time since a date in a more proper format.
 *
 * @param date The date to compare to.
 */
export function timeAgo(date: Date) {
    const todayDate = new Date()
    const today = todayDate.valueOf()

    const seconds = Math.round((today - date.valueOf()) / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (seconds < 5) {
        return "now"
    } else if (seconds < 60) {
        return `${seconds} seconds ago`
    } else if (seconds < 90) {
        return "about a minute ago"
    } else if (minutes < 60) {
        return `${minutes} minutes ago`
    } else if (24 > hours) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`
    } else if (27 > days) {
        return `${days} day${days > 1 ? "s" : ""} ago`
    }

    return date.toLocaleDateString()
}
