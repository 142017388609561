import "../styles/UserPaste.scss"
import { Link } from "react-router-dom"
import { timeAgo } from "@util/Util"
import { Paste } from "@features/pastes/api/models/Paste"

/**
 * A view on a paste that's on a user's page.
 */
export default function ViewUserPaste({ paste }: { paste: Paste }) {
    let title = paste.title.substring(0, 25)

    return (
        <div
            className={`user-paste`}
        >
            <Link to={`/${paste.pasteID}`}>
                {title === "" ? "Untitled Paste" : title}
            </Link>
            <p>{timeAgo(new Date(paste.date))}</p>
        </div>
    )
}