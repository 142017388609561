const KeyApi = () => {
    return (
        <div className="flex flex-row justify-center items-center">
            <div className="md:max-w-2xl max-w-xs island p-6 rounded-lg mt-8 flex items-center justify-center flex-col">
                <div className="mb-6">
                    <h1 className="poppins text-2xl mb-2">Key API</h1>

                    <p>
                        If you're interested in integrating pastebit's key
                        system into your application, you're at the right place.
                    </p>
                    <br />
                    <p>
                        They key system works by providing an interstitial link,
                        which after waiting 5 seconds, the user is granted a
                        "code," which then can be used with our API to return a
                        paste's contents.
                    </p>
                </div>

                <div className="mb-6">
                    <h1 className="poppins text-2xl mb-2">
                        How do I use this?
                    </h1>
                    <p>
                        First, ensure you have a paste that has the key system
                        enabled. To do this, create a paste regularly (through
                        our homepage). After this, copy the small string of text
                        after{" "}
                        <span className="monospace">https://pastebit.org/</span>{" "}
                        in the URL bar. This is your paste ID. Take your paste
                        ID, and return to the keys section of the user
                        dashboard. In here, select the purple "Enable Paste"
                        button, and put your paste ID within the text box.
                    </p>
                    <br />
                    <p>
                        Now, you'll be able to go to{" "}
                        <span className="monospace break-all">
                            https://pastebit.org/interstitial/{"{paste id}"}
                        </span>
                        , and you'll be met with the interstitial to create a
                        key for the paste you created. This key by default
                        expires after 2 hours.
                    </p>
                </div>

                <div>
                    <h1 className="poppins text-2xl mb-2">
                        How do I use that key in my application?
                    </h1>

                    <p>
                        In your application, you'll be able to retrieve the
                        contents of the paste, as well as details of the key
                        itself.
                    </p>

                    <h2 className="poppins text-lg mb-2 mt-4">API Request</h2>
                    <p className="text-md monospace primaryBackground p-2 rounded-sm break-all">
                        GET https://pastebit.org/api/v1/paste/key
                    </p>

                    <h3 className="mb-2 text-lg">Query Parameters</h3>
                    <div className="flex flex-col pl-4 gap-2">
                        <div className="flex md:flex-row flex-col gap-4">
                            <p className="monospace primaryBackground p-1 rounded-sm">
                                ?token=(string)
                            </p>
                            <p>
                                The permission key provided by pastebit.
                                Required.
                            </p>
                        </div>
                        <div className="flex md:flex-row flex-col gap-4">
                            <p className="monospace primaryBackground p-1 rounded-sm">
                                ?raw=(boolean)
                            </p>
                            <p>
                                If the response body should be purely the paste
                                contents. Not Required.
                            </p>
                        </div>
                    </div>

                    <h3 className="md:block hidden mb-2 text-lg">
                        Example Responses
                    </h3>
                    <div className="md:flex hidden flex-col gap-2">
                        <div className="flex flex-col w-full gap-4">
                            <p className="whitespace-pre monospace w-full primaryBackground p-4 rounded-lg">
                                {`GET https://pastebit.org/api/v1/paste/key?token=eAJPeWg2-76ft20cU
                                
{
    "payload": {
        // The expiration in Unix time, MS
        "expires": 1677822745033,
        // Details of the paste this key unlocks
        "paste": {
            "pasteID": "50f81af4",
            "userID": "854a39b7-29a3-46cb-ab47-5fbec1a75462",
            "description": "",
            "hasThumbnail": false,
            "tags": [],
            "date": 1677784871683,
            "title": "",
            "visibility": 2,
            "adConfig": 1
        },
        // The same key provided.
        "key": "eAJPeWg2-76ft20cU"
    }
}
`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeyApi