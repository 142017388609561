import { useAtom } from "jotai"
import { Buffer } from "buffer"
import toast from "react-hot-toast"
import { account } from "@features/users/account/Account.atom"
import { referralStatistics } from "@features/users/referrals/Referrals.atom"
import Spinner from "@components/Spinner"

/**
 * Displays the user's referral code alongside a way to copy a sign up link.
 */
const ReferralCodeDisplay = () => {
    const [referral] = useAtom(referralStatistics)

    // copies the user's referral code (but full signup link)
    const copyReferralLink = () => {
        navigator.clipboard
            .writeText(
                `https://pastebit.org/r?${referral?.referralCode}`
            )
            .then(() => toast.success("Copied sign-up link to clipboard!"))
    }

    return (
        <div className="flex flex-col gap-2">
            <h2 className="poppins text-lg font-semibold text-center">
                Your Referral Code
            </h2>

            <div className="p-4 primaryBackground flex justify-evenly rounded-lg">
                <span className="roboto text-2xl">pastebit.org/r?<span className="font-semibold">{referral ? referral.referralCode : <Spinner />}</span></span>

                {/* Copy signup link */}
                <button className="ml-2" onClick={copyReferralLink}>
                    <i className="fas fa-copy"></i>
                </button>
            </div>
        </div>
    )
}

export default ReferralCodeDisplay
