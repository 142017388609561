import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { getPaste, registerView } from "@features/pastes/api/Pastes"
import { useAtom } from "jotai"
import toast from "react-hot-toast"
import { GetPasteResponse } from "@features/pastes/api/models/GetPasteResponse"
import "@styles/viewer.scss"
import Button from "@components/inputs/Button"
import { INVIS_CLOUDFLARE_SITE_KEY } from "@util/Util"
import { account, getToken } from "@features/users/account/Account.atom"
import useSeo from "@features/layout/hooks/useSeo"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import PasteThumbnailViewer from "@features/pastes/components/PasteThumbnailViewer"
import { useMousePosition } from "@components/hooks/UseMousePosition"
import { Helmet } from "react-helmet"

/**
 * The loading skeleton before loading the paste.
 */
const PasteViewLoadingSkeleton = () => {
    return (
        <>
            <div className="mt-12 flex w-full animate-pulse flex-col items-center">
                <div
                    className={`flex w-full flex-col items-center justify-center gap-4 md:max-w-3xl md:flex-row xl:max-w-4xl`}
                >
                    {/* Side */}
                    <div
                        className={`island flex w-1/2 max-w-lg flex-col justify-between gap-y-5 rounded-lg p-4 text-text dark:text-dark_text`}
                    >
                        <div className="flex flex-col gap-y-2 text-center md:text-left">
                            {/* Paste Title */}
                            <h1 className="text-line poppins break-words pt-2 text-2xl font-medium md:pt-0">
                                <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            </h1>

                            {/* Author of Paste */}
                            <span className="-mt-2 italic">
                                <div
                                    className="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            </span>
                        </div>

                        {/* Paste Description */}
                        <span className="text-center md:text-left">
                            <div className="h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </span>
                    </div>
                </div>
            </div>

            {/* Editor Tabs */}
            <div className="mt-4 animate-pulse p-4 sm:px-10">
                <div className="tabs">
                    <button>
                        <div className="h-2 min-w-[60px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </button>
                </div>

                {/* Viewer */}
                <div className="island pv-box block rounded-tl-none">
                    {/* Content statistics */}
                    <div className="stats monospace">
                        <div className="mt-1.5 h-2 max-w-[120px] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>

                    {/* Viewer itself */}
                    <div
                        id="viewer"
                        className="primaryBackground rounded-b-xl p-4"
                    >
                        <p
                            className="monospace break-all"
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            <div className="mb-2.5 h-2 max-w-[90%] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            <div className="mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            <div className="mb-2.5 h-2 max-w-[70%] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            <div className="mb-2.5 h-2 max-w-[80%] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            <div className="h-2 max-w-[40%] rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * The page to view pastes.
 */
const View = () => {
    let { objectIdSlug: id } = useParams() // id of the paste

    const ref = React.useRef<TurnstileInstance>()

    const [setDescription, setTitle] = useSeo()
    const nav = useNavigate()

    // when the paste is retrieved, it's here
    const [paste, setPaste] = useState<GetPasteResponse>()

    // if the skeleton loading is enabled
    // this is disabled by default to reduce flashing
    const [skeleton, setSkeleton] = useState(false)

    const [sessionToken] = useAtom(getToken)
    const [acc] = useAtom(account)

    // when the title changes, update the HTML title.
    useEffect(() => {
        setTitle(paste?.paste.title ?? "Untitled Paste")
        setDescription(paste?.paste.description ?? "A pastebit paste.")
    }, [paste?.paste.description, paste?.paste.title, setDescription, setTitle])

    // loads the paste on the page load
    useEffect(() => {
        // loads the paste data
        const loadPaste = async () => {
            const token = sessionToken === "" ? undefined : sessionToken

            setTimeout(() => setSkeleton(true), 500)

            try {
                const pasteData = await getPaste(id ?? "", token)

                setPaste(pasteData)

                await registerView(
                    pasteData.viewCallback,
                    ref.current?.getResponse() ?? "",
                )

                // reset the turnstile when finished registering the view
                ref.current?.reset()
            } catch (e) {
                nav("/")
                toast.error("That paste could not be found!", {
                    id: "pasteNotFound",
                })
            }
        }

        if (!paste) {
            loadPaste().then(() => console.log("Finished loading paste!"))
        }
    }, [id, nav, paste, sessionToken])

    // when the user copies the paste
    const copyPaste = () => {
        navigator.clipboard
            .writeText(paste?.content ?? "")
            .then(() => toast.success("Copied paste to clipboard!"))
    }

    const viewerRef = React.createRef<HTMLDivElement>() // ref for the viewer
    const mousePosition = useMousePosition() // current mouse position

    // this handles the functionality that if you're hovered over the viewer,
    // when pressing CTRL + A, you'll select content only within the viewer.
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (viewerRef.current) {
                const el = viewerRef.current.getBoundingClientRect()

                const mouseInBounds =
                    mousePosition.x >= el.left &&
                    mousePosition.x <= el.right &&
                    mousePosition.y >= el.top &&
                    mousePosition.y <= el.bottom

                if (
                    event.ctrlKey &&
                    event.key === "a" &&
                    viewerRef.current &&
                    (mouseInBounds || previouslySelected)
                ) {
                    event.preventDefault()

                    selectViewerContent()
                    setPreviouslySelected(false)
                }
            }
        }

        document.addEventListener("keydown", handleKeyPress)

        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [viewerRef])

    // if the viewer was previously clicked on.
    const [previouslySelected, setPreviouslySelected] = useState(false)

    // this manages the functionality that if the user selects the viewer,
    // the next CTRL + A will select the content within the viewer.
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (
                viewerRef.current &&
                viewerRef.current.contains(event.target as Node)
            ) {
                setPreviouslySelected(true)
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    }, [viewerRef])

    // select all content within the viewer
    const selectViewerContent = () => {
        const selection = window.getSelection()
        const headerElement = document.getElementById("viewer")!!
        selection?.setBaseAndExtent(headerElement, 0, headerElement, 1)
    }

    // while the paste is loading
    if (!paste) {
        return skeleton ? <PasteViewLoadingSkeleton /> : <></>
    }

    return (
        <>
            <Turnstile
                id="viewer"
                options={{
                    size: "invisible",
                }}
                siteKey={INVIS_CLOUDFLARE_SITE_KEY}
                ref={ref}
            />

            {/* Ad Handlers */}
            {paste.paste.monetized && (
                <>
                    <Helmet>
                        <script data-cfasync="false" src="//ddzswov1e84sp.cloudfront.net/?wszdd=1028052"></script>
                    </Helmet>
                </>
            )}

            <div className="mt-12 flex w-full flex-col items-center">
                {/* 728 x 90 */}
                <div id="atContainer-a1d2cfa06571c1f79ce461918cf85b3e"></div>

                <div
                    className={`${
                        paste.paste.hasThumbnail ? "island rounded-lg p-4" : ""
                    } flex w-full flex-col items-center justify-center gap-4 md:max-w-3xl md:flex-row xl:max-w-4xl`}
                >
                    {/* Thumbnail */}
                    <PasteThumbnailViewer paste={paste} />

                    {/* Side */}
                    <div
                        className={`${
                            !paste.paste.hasThumbnail
                                ? "island rounded-lg p-4"
                                : ""
                        } flex w-1/2 max-w-lg flex-col justify-between gap-y-5 text-text dark:text-dark_text`}
                    >
                        <div className="flex flex-col gap-y-2 text-center md:text-left">
                            {/* Paste Title */}
                            <h1 className="text-line poppins break-words pt-2 text-2xl font-medium md:pt-0">
                                {paste.paste.title === ""
                                    ? "Untitled Paste"
                                    : paste.paste.title}
                            </h1>

                            {/* Author of Paste */}
                            <span className="-mt-2 italic">
                                by{" "}
                                <button
                                    className="cursor-pointer italic"
                                    onClick={() =>
                                        nav(`/@${paste.creator.username}`)
                                    }
                                >
                                    {paste.creator
                                        ? paste.creator.username
                                        : "Anonymous"}{" "}
                                    {paste.paste.userID === acc?.user?.userID &&
                                        "(you)"}
                                </button>
                            </span>

                            {/* Paste Information */}
                            <div className="flex flex-row items-center justify-center gap-x-4 md:justify-start">
                                {/* Total Views for the Paste */}
                                <span>
                                    <i className="fa-solid fa-eye" />{" "}
                                    {paste.viewCount.toLocaleString()}
                                </span>

                                {/* Time Posted */}
                                <span>
                                    <i className="fa-solid fa-clock" />{" "}
                                    {new Date(
                                        paste.paste.date,
                                    ).toLocaleDateString()}
                                </span>
                            </div>

                            <div className="flex flex-row gap-2">
                                {/* Game Link Button */}
                                {/*{paste.paste.gameLink && (*/}
                                {/*    <a*/}
                                {/*        target={"_blank"}*/}
                                {/*        className="success-button standardButtonSizing"*/}
                                {/*        href={paste.paste.gameLink}*/}
                                {/*        rel="noopener noreferrer"*/}
                                {/*    >*/}
                                {/*        <i className="fa-solid fa-gamepad"></i>*/}
                                {/*        &nbsp;&nbsp;Game Link*/}
                                {/*    </a>*/}
                                {/*)}*/}

                                {/* YouTube Link Button*/}
                                {/*{paste.paste.youtubeLink &&*/}
                                {/*    !paste.featureYoutubeEmbed && (*/}
                                {/*        <a*/}
                                {/*            target={"_blank"}*/}
                                {/*            className="danger-button standardButtonSizing"*/}
                                {/*            href={paste.paste.youtubeLink}*/}
                                {/*            rel="noopener noreferrer"*/}
                                {/*        >*/}
                                {/*            <i className="fab fa-youtube"></i>*/}
                                {/*            &nbsp;&nbsp;YouTube Link*/}
                                {/*        </a>*/}
                                {/*    )}*/}

                                {/* Modify Buttons */}
                                {paste.paste.userID === acc?.user?.userID && (
                                    <Button
                                        onClick={() =>
                                            nav(`/edit/${paste.paste.pasteID}`)
                                        }
                                    >
                                        <i className="fa-solid fa-pencil"></i>
                                        &nbsp;&nbsp;Edit Paste
                                    </Button>
                                )}
                            </div>
                        </div>

                        {/* Paste Description */}
                        <span className="text-center md:text-left">
                            {paste.paste.description}
                        </span>
                    </div>
                </div>
            </div>

            {/* Pre-paste banner ad */
            }
            <div className="flex items-center justify-center py-5">
                {/*/!* This handles loading the banner ads, but also displays the BloxyBin ad *!/*/}
                {/*{paste.paste.monetized && <AdHandler.Banner />}*/}

                {/*/!* Native Banner *!/*/}
                {/*<div*/}
                {/*    className="max-w-6xl"*/}
                {/*    id="container-4cece80644fd024919291caae44ce429"*/}
                {/*></div>*/}

                <div>
                    <script data-cfasync="false" async type="text/javascript"
                            src="//gorgetmobiles.com/fnbQUlozQI8aK/69530"></script>
                </div>

                {/*/!* New Ad type *!/*/}
                {/*<div><script data-cfasync="false" async type="text/javascript" src="//thirtycabook.com/t7MIst7POAdobZ5/69354"></script></div>*/}
            </div>

            {/* Editor Tabs */
            }
            <div className="mt-4 p-4 sm:px-10">
                <div className="tabs">
                    <button onClick={copyPaste}>
                        <i className="fa-solid fa-copy"></i> Copy
                    </button>
                </div>

                {/* Viewer */}
                <div className="island pv-box block rounded-tl-none">
                    {/* Content statistics */}
                    <div className="stats monospace">
                        <p>{paste.content.length.toLocaleString()} chars</p>
                    </div>

                    {/* Viewer itself */}
                    <div
                        id="viewer"
                        className="primaryBackground rounded-b-xl p-4"
                    >
                        <p
                            className="monospace break-all"
                            ref={viewerRef}
                            style={{ whiteSpace: "pre-wrap" }}
                        >
                            {paste.content}
                        </p>
                    </div>
                </div>
            </div>

            {/* Lower banner ad */
            }
            <div className="flex items-center justify-center">
                {/* 350x250 (1) */}
                <div id="atContainer-4dec5edefbfd1e33a7a29084b72c1f78"></div>
            </div>
        </>
    )
}

export default View
