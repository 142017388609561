const ToS = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col max-w-2xl primaryBackground p-4 items-center rounded-lg">
                Not yet complete.
            </div>
        </div>
    )
}

export default ToS
