import PasteCreator from "@features/pastes/components/PasteCreator"
import RecentPastes from "@features/pastes/components/display/RecentPastes"
import PopularPastes from "@features/pastes/components/display/PopularPastes"
import BannerDisplay from "@features/status/component/BannerDisplay"

/**
 * The /home page
 */
export const Home = () => {
    return (
        <div className={"flex flex-col my-5"}>
            <BannerDisplay />

            <div className="p-5 my-5 flex flex-col gap-8">
                <div className="flex lg:flex-row flex-col gap-8">
                    {/* Homepage Editor */}
                    <PasteCreator />

                    {/* Recent pastes */}
                    <RecentPastes />
                </div>

                {/*Popular pastes*/}
                {/*<PopularPastes />*/}
            </div>
        </div>
    )
}

export default Home
